import React, { useCallback } from 'react';

import { WelcomeModalContent } from './content/WelcomeModalContent';

import { useReactQueryState } from '../../../hooks/base/reactQuery/useReactQueryState';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';

import { words } from '../../../../../locales/keys';

import { CommonCache } from '../../../commonCache';

function WelcomeModal() {
  const { setValue: setIsNotificationVisible, value: isNotificationVisible } =
    useReactQueryState<boolean>(CommonCache.welcomeNotificationCacheKey());

  const handleClose = useCallback<() => void>(() => {
    setIsNotificationVisible(false);
  }, [setIsNotificationVisible]);

  const handleHide = useCallback<() => void>(() => {
    setIsNotificationVisible(true);
  }, [setIsNotificationVisible]);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <SimpleModal
      hideModal={handleHide}
      handleSubmit={handleClose}
      id={CommonCache.welcomeNotificationCacheKey()}
      isOpen={isNotificationVisible}
      withoutCancelButton
      i18nSubmitText={words.close}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="3xl"
    >
      <WelcomeModalContent />
    </SimpleModal>
  );
}

export default WelcomeModal;
