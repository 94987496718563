import { useCallback, useState } from 'react';

import { useReactQueryState } from '../useReactQueryState';
import { useMountEffect } from '../../../../../../common/hooks/useMountEffect';

import { LocalForage } from '../../../../../../utils/LocalForage';

function useLocalForageState<ValueType>(
  cacheKey: string,
  initialValue: ValueType = null
) {
  const [fetched, setFetched] = useState<boolean>(false);
  const localForageCacheKey = `${cacheKey}-persistent`;

  const { setValue: setReactQueryValue, value } = useReactQueryState<ValueType>(
    cacheKey,
    initialValue
  );

  useMountEffect(() => {
    LocalForage.getItem<ValueType>(localForageCacheKey).then((savedValue) => {
      setFetched(true);

      if (savedValue === null) {
        return LocalForage.setItem(localForageCacheKey, initialValue);
      }

      setReactQueryValue(savedValue);
    });
  });

  const setValue = useCallback<(newValue: ValueType) => void>(
    (newValue) => {
      setReactQueryValue(newValue);

      return LocalForage.setItem(localForageCacheKey, newValue);
    },
    [setReactQueryValue, localForageCacheKey]
  );

  return {
    value,
    setValue,
    fetched
  };
}

export default useLocalForageState;
