import React, { useCallback, useState } from 'react';

import { PageNanoID } from '../../../../pages/pagesTypes';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../pages/queries/fetchPageShowPage.query';

import { useReactQueryState } from '../../../hooks/base/reactQuery/useReactQueryState';
import { usePageShowPage } from '../../../../pages/hooks/usePageShowPage';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../helpers/Translate';

import { PageCache } from '../../../../pages/PageCache';

import { words } from '../../../../../locales/keys';

import { CommonCache } from '../../../commonCache';

import {
  WELCOME_INFO_PAGE_NANOID,
  HELP_PAGE_NANOID
} from '../../../../../config';

function WelcomeInfoModal() {
  const { setValue: setIsNotificationVisible, value: isNotificationVisible } =
    useReactQueryState<boolean>(CommonCache.welcomeInfoNotificationCacheKey());

  const [showHelpPage, setShowHelpPage] = useState<boolean>(false);

  const {
    page: helpPage,
    pageLoading: helpPageLoading,
    togglePageData: toggleHelpPageData
  } = usePageShowPage<FetchPageShowPageQueryResponse>({
    uuid: HELP_PAGE_NANOID as PageNanoID,
    query: FETCH_PAGE_SHOW_PAGE_QUERY,
    cacheKey: PageCache.showPageCacheKey(HELP_PAGE_NANOID as PageNanoID)
  });

  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: WELCOME_INFO_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(
        WELCOME_INFO_PAGE_NANOID as PageNanoID
      ),
      initFetch: true
    }
  );

  const handleClose = useCallback<() => void>(() => {
    setIsNotificationVisible(false);
  }, [setIsNotificationVisible]);

  const handleLearnMore = useCallback<() => void>(() => {
    toggleHelpPageData();
    setShowHelpPage(true);
  }, [toggleHelpPageData]);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <SimpleModal
      hideModal={handleClose}
      handleSubmit={handleLearnMore}
      cancelButtonPosition="start"
      id={CommonCache.welcomeInfoNotificationCacheKey()}
      isOpen={isNotificationVisible}
      i18nCancelText={words.close}
      i18nSubmitText={words.learnMore}
      withoutSubmitButton={showHelpPage}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="3xl"
    >
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          <Translate
            id={showHelpPage ? words.helpCenter : words.welcomeToArchivizer}
          />
        </h3>
      </div>
      {showHelpPage ? (
        <LoadingSkeleton loaded={!helpPageLoading}>
          <div className="p-4">
            <span
              dangerouslySetInnerHTML={{
                __html: helpPage?.localizedBody
              }}
            />
          </div>
        </LoadingSkeleton>
      ) : (
        <LoadingSkeleton loaded={!pageLoading}>
          <div className="p-4">
            <span
              dangerouslySetInnerHTML={{
                __html: page?.localizedBody
              }}
            />
          </div>
        </LoadingSkeleton>
      )}
    </SimpleModal>
  );
}

export default WelcomeInfoModal;
