import { Translate } from '../../../../../../../helpers/Translate';
import { stringsKeys, usersKeys } from '../../../../../../../locales/keys';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { CheckBoxField } from '../../../../../../../helpers/FormFields/CheckBoxField';
import React from 'react';
import { usePageShowPage } from '../../../../../../pages/hooks/usePageShowPage';
import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../../../pages/queries/fetchPageShowPage.query';
import { LEGAL_INFORMATION_UPDATE_PAGE_NANOID } from '../../../../../../../config';
import { PageNanoID } from '../../../../../../pages/pagesTypes';
import { PageCache } from '../../../../../../pages/PageCache';
import { IsLoading } from '../../../../../../../types';

interface TermsAcceptModalContentProps {
  isRead: boolean;
  toggleUserTermsAcceptedLoading?: IsLoading;
  toggleIsRead: () => void;
}

function TermsAcceptModalContent({
  isRead,
  toggleUserTermsAcceptedLoading,
  toggleIsRead
}: TermsAcceptModalContentProps) {
  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: LEGAL_INFORMATION_UPDATE_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(
        LEGAL_INFORMATION_UPDATE_PAGE_NANOID as PageNanoID
      ),
      initFetch: true
    }
  );

  return (
    <>
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          <Translate id={usersKeys.legalInformationUpdate} />
        </h3>
      </div>

      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />

          <div className="flex space-x-4">
            <CheckBoxField
              id="isRead"
              name="isRead"
              checked={isRead}
              onChange={toggleIsRead}
              disabled={toggleUserTermsAcceptedLoading}
            />
            <span>
              <Translate
                id={stringsKeys.iHaveReadAndAgreeToTheUpdatedInformation}
              />
            </span>
          </div>
        </div>
      </LoadingSkeleton>
    </>
  );
}

export default TermsAcceptModalContent;
