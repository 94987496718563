import React, { memo } from 'react';

import { useHttpCodeToStatusText } from './hooks/useHttpCodeToStatusText';

import { NextPureLinkHelper } from '../../../helpers/links/NextPureLinkHelper';

import { AuthPath } from '../../../auth/AuthPath';
import { MainPath } from '../../../main/MainPath';

interface ErrorPageProps {
  statusCode: number;
  statusText?: string;
}

function ErrorPage({ statusCode, statusText }: ErrorPageProps) {
  const errorData = useHttpCodeToStatusText(statusCode);

  return (
    <div className="flex-1 flex-col flex justify-center">
      <div className="text-center mb-4">
        <h1 className="mb-2">{statusCode || 500}</h1>
        <h5>{errorData.errorText}</h5>
        <p>{statusText || errorData.statusText}</p>
      </div>

      <div className="text-center">
        {statusCode === 401 ? (
          <NextPureLinkHelper
            href={AuthPath.login()}
            i18nText="words.login_page"
          />
        ) : (
          <NextPureLinkHelper
            href={MainPath.index()}
            i18nText="words.homepage"
          />
        )}
      </div>
    </div>
  );
}

export default memo(ErrorPage);
