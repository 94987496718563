import { gql } from 'graphql-request';

import { UserUUID, UserNanoID, UserTermsAcceptedAt } from '../usersTypes';

export interface FetchUserTermsAcceptedAtQueryResponse {
  nanoId: UserNanoID;
  uuid: UserUUID;
  termsAcceptedAt: UserTermsAcceptedAt;
}

export const FETCH_USER_TERMS_ACCEPTED_AT_QUERY = gql`
  query UserTeamsAcceptedAt($uuid: ID!) {
    user(uuid: $uuid) {
      nanoId
      uuid
      termsAcceptedAt
    }
  }
`;
