import { useCallback } from 'react';
import isAfter from 'date-fns/isAfter';
import isUndefined from 'lodash/isUndefined';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useLocalForageState } from '../../../../../common/hooks/base/reactQuery/useLocalForageState';

import {
  SHOW_PROJECT_TOUR,
  SHOW_PROJECT_TOUR_USER_CREATED_AT
} from '../../../../../../config';

const PROJECT_TOUR_STATE_CACHE_KEY = 'project-tour-state';

function useProjectTourState(
  projectTourStateCacheKey = PROJECT_TOUR_STATE_CACHE_KEY
) {
  const currentUser = useCurrentUser();

  const initialValue =
    isUndefined(SHOW_PROJECT_TOUR_USER_CREATED_AT) ||
    isAfter(
      new Date(currentUser?.createdAt),
      new Date(SHOW_PROJECT_TOUR_USER_CREATED_AT)
    );

  const {
    value: isProjectTourVisible,
    setValue: setIsProjectTourVisible,
    fetched: projectTourStateFetched
  } = useLocalForageState<boolean>(projectTourStateCacheKey, initialValue);

  const toggleProjectTour = useCallback<() => void>(
    () => setIsProjectTourVisible(!isProjectTourVisible),
    [setIsProjectTourVisible, isProjectTourVisible]
  );

  const hideProjectTour = useCallback<() => void>(
    () => setIsProjectTourVisible(false),
    [setIsProjectTourVisible]
  );

  const showProjectTour = useCallback<() => void>(
    () => setIsProjectTourVisible(true),
    [setIsProjectTourVisible]
  );

  return {
    hideProjectTour,
    isProjectTourVisible:
      (isProjectTourVisible && projectTourStateFetched) ||
      SHOW_PROJECT_TOUR === 'true',
    showProjectTour,
    toggleProjectTour
  };
}

export default useProjectTourState;
