import { gql } from 'graphql-request';
import { UserTermsAcceptedAt, UserNanoID, UserUUID } from '../usersTypes';

export interface ToggleUserTermsAcceptedQueryResponse {
  uuid: UserUUID;
  nanoId: UserNanoID;
  termsAcceptedAt: UserTermsAcceptedAt;
}

export const TOGGLE_USER_TERMS_ACCEPTED_AT_QUERY = gql`
  mutation ToggleUserTermsAccepted($uuid: ID!) {
    toggleUserTermsAccepted(input: { uuid: $uuid }) {
      status
      recordUuid
      record {
        uuid
        nanoId
        termsAcceptedAt
      }
      errors {
        fullMessages
      }
    }
  }
`;
