import { IconsEnum } from '../../../../../assets/icons/types';

import { useProjectTourState } from '../../ProjectTour/hooks/useProjectTourState';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { words } from '../../../../../locales/keys';

function ShowProjectTourButton() {
  const { showProjectTour } = useProjectTourState();

  return (
    <PureIconButtonHelper
      className="focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white hidden sm:flex items-center gap-1.5 mb-px"
      i18nText={words.onboardingTour}
      icon={IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE}
      iconClassName="h-3.5 w-3.5"
      onClick={showProjectTour}
    />
  );
}
export default ShowProjectTourButton;
