import { gql } from 'graphql-request';

import {
  InvoiceAmount,
  InvoiceAvInvoiceBillingInfoAddress,
  InvoiceAvInvoiceBillingInfoBankDetails,
  InvoiceAvInvoiceBillingInfoCity,
  InvoiceAvInvoiceBillingInfoCompanyName,
  InvoiceAvInvoiceBillingInfoContactEmail,
  InvoiceAvInvoiceBillingInfoContactName,
  InvoiceAvInvoiceBillingInfoContactPhone,
  InvoiceAvInvoiceBillingInfoCountry,
  InvoiceAvInvoiceBillingInfoID,
  InvoiceAvInvoiceBillingInfoName,
  InvoiceAvInvoiceBillingInfoNanoID,
  InvoiceAvInvoiceBillingInfoPaymentMethods,
  InvoiceAvInvoiceBillingInfoState,
  InvoiceAvInvoiceBillingInfoVatNumber,
  InvoiceAvInvoiceBillingInfoZipCode,
  InvoiceClientId,
  InvoiceClientStatus,
  InvoiceCurrencyRateID,
  InvoiceDisabledBindButton,
  InvoiceDisabledCancelButton,
  InvoiceDisabledPayCardButton,
  InvoiceDisabledPayDepositButton,
  InvoiceDisabledPayWireButton,
  InvoiceDisabledRefundButton,
  InvoiceDisabledSendButton,
  InvoiceDisabledUnbindButton,
  InvoiceDisabledUncollectibleButton,
  InvoiceDisabledVoidButton,
  InvoiceDueDate,
  InvoiceGeneralLedgerCompanyID,
  InvoiceGeneralLedgerCompanyName,
  InvoiceGeneralLedgerCompanyNanoID,
  InvoiceID,
  InvoiceInvoiceBillingInfoAddress,
  InvoiceInvoiceBillingInfoCity,
  InvoiceInvoiceBillingInfoCompanyName,
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoContactPhone,
  InvoiceInvoiceBillingInfoCountry,
  InvoiceInvoiceBillingInfoFirstName,
  InvoiceInvoiceBillingInfoID,
  InvoiceInvoiceBillingInfoLastName,
  InvoiceInvoiceBillingInfoNanoID,
  InvoiceInvoiceBillingInfoState,
  InvoiceInvoiceBillingInfoVatNumber,
  InvoiceInvoiceBillingInfoZipCode,
  InvoiceInvoiceType,
  InvoiceIssueDate,
  InvoiceNanoID,
  InvoiceNotes,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceReturnReasons,
  InvoiceSelectedCurrency,
  InvoiceSelectedCurrencyRate,
  InvoiceShowBindButton,
  InvoiceShowCancelButton,
  InvoiceShowPayCardButton,
  InvoiceShowPayDepositButton,
  InvoiceShowPayWireButton,
  InvoiceShowRefundButton,
  InvoiceShowSendButton,
  InvoiceShowUnbindButton,
  InvoiceShowUncollectibleButton,
  InvoiceShowVoidButton,
  InvoiceSmartContractID,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceTempTotalConverted,
  InvoiceTerms,
  InvoiceTotal,
  InvoiceTotalConverted,
  InvoiceUUID,
  InvoiceViewSettings
} from '../invoicesTypes';
import {
  TeamAccountReceivables,
  TeamEmail,
  TeamImageFile,
  TeamUUID
} from '../../teams/teamsTypes';
import {
  ItemPrice,
  ItemSmartContractID,
  ItemViewPrice,
  ItemViewPriceCurrency
} from '../../items/itemsTypes';
import { ItemCategoryName } from '../../itemCategories/itemCategoriesTypes';
import { ItemTypeName } from '../../itemTypes/itemTypesTypes';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFields/invoiceCustomFieldsTypes';
import {
  ItemTagID,
  ItemTagName,
  ItemTagPosition
} from '../../itemTags/itemTagsTypes';
import { ID } from '../../../types';

export interface FetchInvoicesInMessagesQueryResponse {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  dueDate: InvoiceDueDate;
  issueDate: InvoiceIssueDate;
  amount: InvoiceAmount;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  invoiceType: InvoiceInvoiceType;
  invoiceTotal: InvoiceTotal;
  invoiceTotalConverted: InvoiceTotalConverted;
  invoiceTempTotalConverted: InvoiceTempTotalConverted;
  showPayWireButton: InvoiceShowPayWireButton;
  disabledPayWireButton: InvoiceDisabledPayWireButton;
  showUncollectibleButton: InvoiceShowUncollectibleButton;
  disabledUncollectibleButton: InvoiceDisabledUncollectibleButton;
  showBindButton: InvoiceShowBindButton;
  disabledBindButton: InvoiceDisabledBindButton;
  showUnbindButton: InvoiceShowUnbindButton;
  disabledUnbindButton: InvoiceDisabledUnbindButton;
  showPayCardButton: InvoiceShowPayCardButton;
  disabledPayCardButton: InvoiceDisabledPayCardButton;
  showSendButton: InvoiceShowSendButton;
  disabledSendButton: InvoiceDisabledSendButton;
  showRefundButton: InvoiceShowRefundButton;
  disabledRefundButton: InvoiceDisabledRefundButton;
  showCancelButton: InvoiceShowCancelButton;
  disabledCancelButton: InvoiceDisabledCancelButton;
  showVoidButton: InvoiceShowVoidButton;
  disabledVoidButton: InvoiceDisabledVoidButton;
  showPayDepositButton: InvoiceShowPayDepositButton;
  disabledPayDepositButton: InvoiceDisabledPayDepositButton;
  clientId: InvoiceClientId;
  clientStatus: InvoiceClientStatus;
  prepayment: InvoicePrepayment;
  returnReason: InvoiceReturnReasons;
  terms: InvoiceTerms;
  notes?: InvoiceNotes;
  prepaymentAmount: InvoicePrepaymentAmount;
  currencyRateId: InvoiceCurrencyRateID;
  selectedCurrency: InvoiceSelectedCurrency;
  selectedCurrencyRate: InvoiceSelectedCurrencyRate;
  viewSettings: InvoiceViewSettings;
  generalLedger: {
    company: {
      id: InvoiceGeneralLedgerCompanyID;
      nanoId: InvoiceGeneralLedgerCompanyNanoID;
      name: InvoiceGeneralLedgerCompanyName;
      accountReceivables: TeamAccountReceivables;
      uuid: TeamUUID;
      email: TeamEmail;
      image: {
        file: TeamImageFile;
      } | null;
    };
  };
  invoiceBillingInfo: {
    id: InvoiceInvoiceBillingInfoID;
    nanoId: InvoiceInvoiceBillingInfoNanoID;
    companyName: InvoiceInvoiceBillingInfoCompanyName;
    firstName: InvoiceInvoiceBillingInfoFirstName;
    lastName: InvoiceInvoiceBillingInfoLastName;
    contactPhone: InvoiceInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceInvoiceBillingInfoContactEmail;
    country: InvoiceInvoiceBillingInfoCountry;
    state: InvoiceInvoiceBillingInfoState;
    city: InvoiceInvoiceBillingInfoCity;
    address: InvoiceInvoiceBillingInfoAddress;
    zipCode: InvoiceInvoiceBillingInfoZipCode;
    vatNumber?: InvoiceInvoiceBillingInfoVatNumber;
  };
  avInvoiceBillingInfo: {
    id: InvoiceAvInvoiceBillingInfoID;
    nanoId: InvoiceAvInvoiceBillingInfoNanoID;
    name: InvoiceAvInvoiceBillingInfoName;
    companyName: InvoiceAvInvoiceBillingInfoCompanyName;
    contactName: InvoiceAvInvoiceBillingInfoContactName;
    contactPhone: InvoiceAvInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceAvInvoiceBillingInfoContactEmail;
    country: InvoiceAvInvoiceBillingInfoCountry;
    state: InvoiceAvInvoiceBillingInfoState;
    city: InvoiceAvInvoiceBillingInfoCity;
    address: InvoiceAvInvoiceBillingInfoAddress;
    zipCode: InvoiceAvInvoiceBillingInfoZipCode;
    paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    bankDetails: InvoiceAvInvoiceBillingInfoBankDetails;
    vatNumber?: InvoiceAvInvoiceBillingInfoVatNumber;
  };
  secondaryInvoice?: {
    id: InvoiceID;
    nanoId: InvoiceNanoID;
    amount: InvoiceAmount;
    showPayWireButton: InvoiceShowPayWireButton;
    disabledPayWireButton: InvoiceDisabledPayWireButton;
    showPayCardButton: InvoiceShowPayCardButton;
    disabledPayCardButton: InvoiceDisabledPayCardButton;
    showPayDepositButton: InvoiceShowPayDepositButton;
    disabledPayDepositButton: InvoiceDisabledPayDepositButton;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
  };
  childInvoices: {
    id: InvoiceID;
    item: {
      itemTypeName: ItemTypeName;
      itemCategoryName: ItemCategoryName;
      price: ItemPrice;
      viewPrice: ItemViewPrice;
      viewPriceCurrency: ItemViewPriceCurrency;
      smartContractId: ItemSmartContractID;
      itemItemTags: {
        id: ID;
        itemTag: {
          id: ItemTagID;
          name: ItemTagName;
        };
        position: ItemTagPosition;
      }[];
    };
    smartContract: {
      id: InvoiceSmartContractID;
      task: {
        createdAt: InvoiceSmartContractTaskCreatedAt;
        nanoId: InvoiceSmartContractTaskNanoID;
        name: InvoiceSmartContractTaskName;
        owner: {
          nanoId: InvoiceSmartContractTaskOwnerNanoID;
          fullName: InvoiceSmartContractTaskOwnerFullName;
        };
        project: {
          createdAt: InvoiceSmartContractTaskProjectCreatedAt;
          nanoId: InvoiceSmartContractTaskProjectNanoID;
          name: InvoiceSmartContractTaskProjectName;
          owner: {
            nanoId: InvoiceSmartContractProjectOwnerNanoID;
            fullName: InvoiceSmartContractProjectOwnerFullName;
          };
        };
      };
    };
  }[];
}

export const FETCH_INVOICES_IN_MESSAGES_QUERY = gql`
  query InvoicesInMessages(
    $filters: InvoicesFilters
    $sort: [InvoicesSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    invoices(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      appVersion
      nodes {
        amount
        comment
        avInvoiceBillingInfo {
          address
          bankDetails
          city
          companyName
          contactName
          contactEmail
          contactPhone
          country
          id
          name
          nanoId
          paymentMethod
          state
          vatNumber
          zipCode
        }
        childInvoices {
          id
          item {
            itemTypeName
            itemCategoryName
            price
            viewPrice
            viewPriceCurrency
            smartContractId
            itemItemTags {
              id
              itemTag {
                id
                name
              }
              position
            }
          }
          smartContract {
            id
            task {
              createdAt
              nanoId
              name
              owner {
                nanoId
                fullName
              }
              project {
                createdAt
                nanoId
                name
                owner {
                  nanoId
                  fullName
                }
              }
            }
          }
        }
        clientId
        clientStatus
        currencyRateId
        selectedCurrency
        selectedCurrencyRate
        deferredPaymentDays
        description
        disabledPayDepositButton
        disabledPayWireButton
        disabledUncollectibleButton
        disabledBindButton
        disabledUnbindButton
        disabledPayCardButton
        disabledSendButton
        disabledRefundButton
        disabledCancelButton
        disabledVoidButton
        dueDate
        issueDate
        generalLedger {
          company {
            id
            nanoId
            name
            accountReceivables
            uuid
          }
        }
        id
        nanoId
        invoiceBillingInfo {
          address
          city
          companyName
          contactEmail
          contactPhone
          country
          firstName
          id
          lastName
          nanoId
          state
          vatNumber
          zipCode
        }
        invoiceCustomFields {
          id
          label
          body
          location
          order
        }
        invoiceTotal
        invoiceTotalConverted
        invoiceTempTotalConverted
        invoiceType
        nanoId
        notes
        prepayment
        prepaymentAmount
        returnReason
        secondaryInvoice {
          amount
          clientStatus
          disabledPayCardButton
          disabledPayDepositButton
          disabledPayWireButton
          id
          nanoId
          showPayCardButton
          showPayDepositButton
          showPayWireButton
          status
        }
        showPayDepositButton
        showPayWireButton
        showUncollectibleButton
        showBindButton
        showUnbindButton
        showPayCardButton
        showSendButton
        showRefundButton
        showCancelButton
        showVoidButton
        status
        terms
        viewSettings
        uuid
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
