import { UserGqlError, UserUUID } from '../../usersTypes';
import { FetchItemCacheKey } from '../../../../types';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleUserTermsAcceptedOptions<ToggleUserTermsAcceptedRecordType> {
  query: string;
  cacheKeys?: FetchItemCacheKey[];
  onSuccess?: (responseRecord: ToggleUserTermsAcceptedRecordType) => void;
}

export interface ToggleUserTermsAcceptedResponse<
  ToggleUserTermsAcceptedRecordType
> {
  toggleUserTermsAccepted: {
    status: string;
    record: ToggleUserTermsAcceptedRecordType;
    errors: ToggleUserTermsAcceptedError;
  };
}

export interface ToggleUserTermsAcceptedInput {
  uuid: UserUUID;
}

export interface ToggleUserTermsAcceptedError {
  fullMessages: UserGqlError;
}

const action = 'toggleUserTermsAccepted';

function useToggleUserTermsAccepted<ToggleUserTermsAcceptedRecordType>({
  query,
  cacheKeys,
  onSuccess
}: ToggleUserTermsAcceptedOptions<ToggleUserTermsAcceptedRecordType>) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleUserTermsAcceptedInput,
    ToggleUserTermsAcceptedResponse<ToggleUserTermsAcceptedRecordType>,
    ToggleUserTermsAcceptedError,
    ToggleUserTermsAcceptedRecordType
  >({ action, query, cacheKeys, onSuccess });

  return {
    toggleUserTermsAcceptedData: updateQueryData,
    toggleUserTermsAcceptedError: updateQueryError,
    toggleUserTermsAcceptedLoading: updateQueryLoading,
    toggleUserTermsAcceptedErrorMessage: updateQueryErrorMessage,
    toggleUserTermsAccepted: updateQuery,
    toggleUserTermsAcceptedReset: updateQueryReset
  };
}

export default useToggleUserTermsAccepted;
