import React from 'react';

import { PageNanoID } from '../../../../../../pages/pagesTypes';

import { usePageShowPage } from '../../../../../../pages/hooks/usePageShowPage';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../../../pages/queries/fetchPageShowPage.query';

import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../helpers/Translate';

import { PageCache } from '../../../../../../pages/PageCache';
import { WELCOME_PAGE_NANOID } from '../../../../../../../config';
import { words } from '../../../../../../../locales/keys';

function WelcomeModalContent() {
  const { page, pageLoading } = usePageShowPage<FetchPageShowPageQueryResponse>(
    {
      uuid: WELCOME_PAGE_NANOID as PageNanoID,
      query: FETCH_PAGE_SHOW_PAGE_QUERY,
      cacheKey: PageCache.showPageCacheKey(WELCOME_PAGE_NANOID as PageNanoID),
      initFetch: true
    }
  );

  return (
    <>
      <div className="flex justify-center items-center border-b border-gray-300 dark:border-gray-700 py-4">
        <h3 className="text-base sm:text-lg text-center flex-1 truncate">
          <Translate id={words.welcomeToArchivizer} />
        </h3>
      </div>
      <LoadingSkeleton loaded={!pageLoading}>
        <div className="p-4">
          <span
            dangerouslySetInnerHTML={{
              __html: page?.localizedBody
            }}
          />
        </div>
      </LoadingSkeleton>
    </>
  );
}

export default WelcomeModalContent;
