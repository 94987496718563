import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { PageNanoID } from '../../../../pages/pagesTypes';

import {
  FETCH_PAGE_SHOW_PAGE_QUERY,
  FetchPageShowPageQueryResponse
} from '../../../../pages/queries/fetchPageShowPage.query';

import { useReactQueryState } from '../../../hooks/base/reactQuery/useReactQueryState';
import { usePageShowPage } from '../../../../pages/hooks/usePageShowPage';

import { Icon } from '../../../../../helpers/Icon';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { NOTIFICATION_PAGE_NANOID } from '../../../../../config';
import { PageCache } from '../../../../pages/PageCache';

const cacheKey = 'global-notification';

function GlobalNotification() {
  const { setValue: setIsNotificationVisible, value: isNotificationVisible } =
    useReactQueryState<boolean>(cacheKey, true);

  const { page } = usePageShowPage<FetchPageShowPageQueryResponse>({
    uuid: NOTIFICATION_PAGE_NANOID as PageNanoID,
    query: FETCH_PAGE_SHOW_PAGE_QUERY,
    cacheKey: PageCache.showPageCacheKey(
      NOTIFICATION_PAGE_NANOID as PageNanoID
    ),
    initFetch: true
  });

  const handleClose = useCallback<() => void>(() => {
    setIsNotificationVisible(false);
  }, [setIsNotificationVisible]);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <div className="bg-blue-600 text-white">
      <div className="lg:px-8 px-3 py-2.5 sm:px-6">
        <div className="flex flex-wrap justify-between">
          <div className="flex flex-1 items-start">
            <span className="bg-blue-800 flex p-2 rounded-lg">
              <Icon icon={IconsEnum.WRENCH_SCREWDRIVER_OUTLINE} />
            </span>

            <div className="sm:leading-tight ml-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: page?.localizedBody
                }}
              />
            </div>
          </div>

          <div className="order-2 shrink-0 sm:order-3 ml-2">
            <PureIconButtonHelper
              icon={IconsEnum.X}
              onClick={handleClose}
              className="-mr-1 flex p-2 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalNotification;
