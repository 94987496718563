import React, { Fragment, ReactNode } from 'react';

import { Permissions } from '../../permissions';

import { useCurrentUser } from '../../../auth/hooks/useAuth/useAuth';

import { ErrorPage } from '../../../app/components/ErrorPage';

interface CheckPermissionLayoutProps {
  action: string;
  appAction: Permissions | string;
  withoutActionError?: boolean;
  children: ReactNode;
}

function CheckPermissionLayout({
  action,
  appAction,
  withoutActionError,
  children
}: CheckPermissionLayoutProps) {
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  if (
    currentUser.hasPermissions(appAction) &&
    currentUser.hasPermissions(action)
  ) {
    return <Fragment>{children}</Fragment>;
  }

  return withoutActionError ? null : <ErrorPage statusCode={403} />;
}
export default CheckPermissionLayout;
