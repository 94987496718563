import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  Fragment,
  memo,
  ReactNode
} from 'react';
import cl from 'classnames';

import { Translate } from '../../Translate';

interface CheckBoxFieldProps {
  className?: string;
  disabled?: boolean;
  error?: string | null;
  errorClassName?: string;
  id: string;
  checkBoxClassName?: string;
  label?: string | ReactNode;
  i18nLabel?: string;
  labelClassName?: string;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  checked?: boolean;
}

const CheckBoxField = React.forwardRef<HTMLInputElement, CheckBoxFieldProps>(
  (
    {
      className,
      disabled,
      error,
      errorClassName,
      id,
      checkBoxClassName,
      label,
      i18nLabel,
      labelClassName,
      name,
      onBlur,
      onChange,
      required,
      checked
    }: CheckBoxFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Fragment>
        <div className={cl(className || 'flex items-center')}>
          <input
            className={cl(checkBoxClassName || 'basic-checkbox', {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:border-red-700':
                error
            })}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            required={required}
            type="checkbox"
            checked={checked}
          />

          {i18nLabel || label ? (
            <label
              htmlFor={id}
              className={cl(
                labelClassName ||
                  'ml-2 block text-sm text-gray-900 dark:text-gray-100'
              )}
            >
              {i18nLabel ? <Translate id={i18nLabel} /> : label}
            </label>
          ) : null}
        </div>

        {error && (
          <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </Fragment>
    );
  }
);

CheckBoxField.displayName = 'CheckBoxField';

export default memo(CheckBoxField);
