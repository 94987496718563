import React, { useCallback, useState } from 'react';

import { TOGGLE_USER_TERMS_ACCEPTED_AT_QUERY } from '../../../queries/toggleUserTermsAccepted.query';
import {
  FETCH_USER_TERMS_ACCEPTED_AT_QUERY,
  FetchUserTermsAcceptedAtQueryResponse
} from '../../../queries/fetchUserTermsAcceptedAt.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useToggleUserTermsAccepted } from '../../../hooks/useToggleUserTermsAccepted';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useUser } from '../../../hooks/useUser';

import { TermsAcceptModalContent } from './content/TermsAcceptModalContent';

import { SimpleModal } from '../../../../../helpers/modals/SimpleModal';
import { UserCache } from '../../../UserCache';

import { words } from '../../../../../locales/keys';

function TermsAcceptModal() {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const currentUser = useCurrentUser();

  const { userError } = useUser<FetchUserTermsAcceptedAtQueryResponse>({
    cacheKey: UserCache.userTermsAcceptedAtCacheKey(),
    uuid: currentUser.nanoId,
    query: FETCH_USER_TERMS_ACCEPTED_AT_QUERY,
    options: {
      onSuccess: (data) => {
        if (data?.user) {
          setIsNotificationVisible(!data.user.termsAcceptedAt);
        }
      }
    }
  });

  const {
    toggleUserTermsAccepted,
    toggleUserTermsAcceptedErrorMessage,
    toggleUserTermsAcceptedLoading
  } = useToggleUserTermsAccepted({
    query: TOGGLE_USER_TERMS_ACCEPTED_AT_QUERY,
    cacheKeys: [UserCache.userTermsAcceptedAtCacheKey()]
  });

  const [isRead, setIsRead] = useState(false);

  useShowToastOnErrorChange({
    error: toggleUserTermsAcceptedErrorMessage || userError
  });

  const handleClose = useCallback<() => void>(async () => {
    await toggleUserTermsAccepted({ uuid: currentUser.uuid });

    setIsNotificationVisible(false);
  }, [currentUser.uuid, toggleUserTermsAccepted]);

  const handleHide = useCallback<() => void>(() => {
    setIsNotificationVisible(true);
  }, [setIsNotificationVisible]);

  const toggleIsRead = useCallback<() => void>(() => {
    setIsRead((prevValue) => !prevValue);
  }, []);

  if (!isNotificationVisible) {
    return null;
  }

  return (
    <SimpleModal
      hideModal={handleHide}
      handleSubmit={handleClose}
      id="TermsAcceptModal"
      isOpen={isNotificationVisible}
      withoutCancelButton
      i18nSubmitText={words.accept}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      modalSize="3xl"
      isLoading={toggleUserTermsAcceptedLoading}
      submitDisabled={!isRead}
    >
      <TermsAcceptModalContent
        isRead={isRead}
        toggleIsRead={toggleIsRead}
        toggleUserTermsAcceptedLoading={toggleUserTermsAcceptedLoading}
      />
    </SimpleModal>
  );
}

export default TermsAcceptModal;
