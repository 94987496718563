import React from 'react';

import SplitPane, { SplitPaneProps, SplitPaneState } from 'react-split-pane';

declare class SplitPaneAdapter extends React.Component<
  React.PropsWithChildren<SplitPaneProps>,
  SplitPaneState
> {
  constructor();

  onMouseDown(event: MouseEvent): void;

  onTouchStart(event: TouchEvent): void;

  onMouseMove(event: MouseEvent): void;

  onTouchMove(event: TouchEvent): void;

  onMouseUp(): void;

  static getSizeUpdate(
    props: SplitPaneProps,
    state: SplitPaneState
  ): Partial<SplitPaneState>;

  static defaultProps: SplitPaneProps;
}

// Fix children typescript error
export default SplitPane as typeof SplitPaneAdapter;
